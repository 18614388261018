import { defineStore } from "pinia";

const useUserNavStore = defineStore("userNav", {
  state: () => ({
    stores: [],
    currentUser: null,
    showReferAFriend: false,
    showDwt: false,
    hideCredit: false,
    credit: 0,
    creditLabel: null,
    oAuthProviders: null,
    adminUser: false,
    dropdownPaths: null,
    newPasswordPath: null,
    loginModalShowing: false,
    registerModalShowing: false,
    autoOptIn: false,
    accountModalState: "login"
  }),
  getters: {
    dropdownPathsExcludingLogout(state) {
      const { logoutPath, ...paths } = state.dropdownPaths;
      return paths;
    },
    filteredPaths(state) {
      let paths = this.dropdownPathsExcludingLogout;

      if (!state.showDwt) {
        const { dwtPath, ...rest } = paths;
        paths = rest;
      }

      if (!state.showReferAFriend) {
        const { accountReferPath, ...rest } = paths;
        paths = rest;
      }

      return paths;
    },
    logoutPath(state) {
      return state.dropdownPaths.logoutPath;
    }
  }
});

export default useUserNavStore;
