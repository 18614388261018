<template>
  <div>
    <p
      v-if="label"
      class="typeset-6 mb-3">
      {{ label }}
    </p>
    <div class="flex gap-x-3">
      <text-input
        v-for="(value, index) in correctDatePositions"
        :key="index"
        ref="textInputs"
        v-model="value.value"
        class="w-1/3"
        :data-test="value.reference"
        :input-id="value.reference"
        :placeholder="value.placeholder"
        number-only-input
        :errors="errors"
        hide-error-message
        :max-length="value.maxLength" />
    </div>
    <div
      v-if="props.errors.length"
      data-cy="input-error-message"
      class="error-box text-red typeset-8">
      {{ errors.join(", ") }}
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import TextInput from "./TextInput.vue";

const textInputs = ref(null);

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  errors: {
    type: Array,
    default: () => []
  }
});

const dateOfBirth = reactive({
  day: "",
  month: "",
  year: ""
});

const day = ref(dateOfBirth.day);
const month = ref(dateOfBirth.month);
const year = ref(dateOfBirth.year);

const dayValue = reactive({
  value: day,
  placeholder: "DD",
  maxLength: 2,
  reference: "dobDay"
});

const monthValue = reactive({
  value: month,
  placeholder: "MM",
  maxLength: 2,
  reference: "dobMonth"
});

const yearValue = reactive({
  value: year,
  placeholder: "YYYY",
  maxLength: 4,
  reference: "dobYear"
});

const inUsStore = computed(() => {
  return window.countryCode === "/us";
});

const correctDatePositions = computed(() => {
  if (inUsStore.value) {
    return [monthValue, dayValue, yearValue];
  }

  return [dayValue, monthValue, yearValue];
});

const formattedDate = computed(() => {
  if (!day.value && !month.value && !year.value) {
    return "";
  }

  const yearFormatted = year.value;
  const monthFormatted = month.value
    ? month.value.toString().padStart(2, "0")
    : "";
  const dayFormatted = day.value ? day.value.toString().padStart(2, "0") : "";

  return `${yearFormatted}/${monthFormatted}/${dayFormatted}`;
});

watch(formattedDate, newDate => {
  emit("update:modelValue", newDate);
});

watch(day, newDay => {
  if (newDay.length > 1) {
    if (inUsStore.value) {
      textInputs.value[2].focus();
    } else {
      textInputs.value[1].focus();
    }
  }
});

watch(month, newMonth => {
  if (newMonth.length > 1) {
    if (inUsStore.value) {
      textInputs.value[1].focus();
    } else {
      textInputs.value[2].focus();
    }
  }
});
</script>
