export default function validDateOfBirth(dateString) {
  // We need to do some quite strict checking to ensure the DOB is correct
  // It's a bit of pain getting this completely right, because there are so many different date formats
  // With some SO / GPT help I've come up with this (comments below):

  // First we want to check the pattern == (YYYY/MM/DD)
  if (!/^\d{4}\/\d{2}\/\d{2}$/.test(dateString)) {
    return false;
  }

  const parts = dateString.split("/");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);

  // We use this date object to create a JS date and do the checks against it for validity
  const date = new Date(year, month, day);

  // Check the date is in the past
  if (date > Date.now()) {
    return false;
  }

  // Check the date is a valid type
  if (Number.isNaN(date.getTime())) {
    return false;
  }

  // This final check checks the input dates agains the new date object
  // If any don't line up, the date is incorrect and will return false
  return (
    date.getDate() === day &&
    date.getMonth() === month &&
    date.getFullYear() === year
  );
}
